.footer {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1rem;

  a {
    color: $link-color;
  }
}
