.navigation {
  height: 6rem;
  width: 100%;

  a,
  span {
    display: inline;
    font-size: 1.7rem;
    font-family: $font-family;
    font-weight: 600;
    color: $fg-color;
  }

  a {

    &:hover,
    &:focus {
      color: $link-color;
    }
  }

  .navigation-title {
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  .navigation-list {
    float: right;
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;

    @media only screen and (max-width: 768px) {
      position: relative;
      top: 2rem;
      right: 0;
      z-index: 5;
      visibility: hidden;
      opacity: 0;
      padding: 0;
      max-height: 0;
      width: 100%;
      background-color: $bg-color;
      border-top: solid 2px $alt-bg-color;
      border-bottom: solid 2px $alt-bg-color;
      transition: opacity 0.25s, max-height 0.15s linear;
    }

    .navigation-item {
      float: left;
      margin: 0;
      position: relative;

      @media only screen and (max-width: 768px) {
        float: none !important;
        text-align: center;

        a,
        span {
          line-height: 5rem;
        }
      }

      a,
      span {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .separator {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .menu-separator {
      @media only screen and (max-width: 768px) {
        border-top: 2px solid $fg-color;
        margin: 0 8rem;

        span {
          display: none;
        }
      }
    }
  }

  #dark-mode-toggle {
    margin: 1.7rem 0;
    font-size: 2.4rem;
    line-height: inherit;
    bottom: 2rem;
    left: 2rem;
    z-index: 100;
    position: fixed;
  }

  #menu-toggle {
    display: none;

    @media only screen and (max-width: 768px) {
      &:checked+label>i {
        color: $alt-bg-color;
      }

      &:checked+label+ul {
        visibility: visible;
        opacity: 1;
        max-height: 100rem;
      }
    }
  }

  .menu-button {
    display: none;

    @media only screen and (max-width: 768px) {
      position: relative;
      display: block;
      font-size: 2.4rem;
      font-weight: 400;
    }

    i {

      &:hover,
      &:focus {
        color: $alt-fg-color;
      }
    }
  }

  i {
    color: $fg-color;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $link-color;
    }
  }
}
