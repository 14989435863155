.float-container {
  bottom: 2rem;
  right: 2rem;
  z-index: 100;
  position: fixed;
  font-size: 1.6em;

  a {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    font-size: 2rem;
    color: $alt-fg-color;
    background-color: $alt-bg-color;
    border-radius: 0.2rem;
    opacity: 50%;
    transition: all 0.25s ease-in;

    &:hover,
    &:focus {
      color: $link-color;
      opacity: 100%;

      @media only screen and (max-width: 768px) {
        color: $alt-fg-color;
        opacity: 50%;
      }
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
