/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: '#{$fa-font-family}';
  src: url('#{$fa-font-path}/forkawesome-webfont.woff');
  src: url('#{$fa-font-path}/forkawesome-webfont.woff') format('woff'),
    url('#{$fa-font-path}/forkawesome-webfont.ttf') format('truetype'),
    url('#{$fa-font-path}/forkawesome-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
