.taxonomy {
  li {
    display: inline-block;
    margin: 0.9rem;
  }

  .taxonomy-element  {
    display: block;
    padding: 0.3rem 0.9rem;
    background-color: $alt-bg-color;
    border-radius: 0.6rem;

    a {
      color: $fg-color;
    }
    a:active {
      color: $fg-color;
    }
  }
}
